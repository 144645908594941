import React, { Component } from "react";
// Components
import Helmet from "react-helmet";

import Hero from "slices/Hero";
import Story from "slices/Story";
import StaffGrid from "slices/StaffGrid";
import AffiliateList from "slices/AffiliateList";
import BigCallout from "slices/BigCallout";
import getPageMeta from "utils/getPageMeta";

// graphql
import { graphql } from "gatsby";
import FeatureContentWithImage from "slices/FeatureContentWithImage";
import FeatureContent from "slices/FeatureContent";

class CrisisPage extends Component {
  state = {
    page: this.props.data.page
  };

  renderSlice = slice => {
    if (!slice) {
      return null;
    }

    let Cmp;
    switch (slice.slice_type) {
      case "hero":
        slice.primary.theme = "red";
        slice.large = true;
        slice.compressed = false;
        Cmp = Hero;
        break;
      case "feature_content_with_image":
        slice.large = true;
        Cmp = FeatureContentWithImage;
        break;
      case "feature_content":
        slice.large = true;
        Cmp = FeatureContent;
        break;
      case "story":
        Cmp = Story;
        break;
      case "staff_grid":
        Cmp = StaffGrid;
        break;
      case "partner_callouts":
        Cmp = AffiliateList;
        break;
      case "callout":
        Cmp = BigCallout;
        break;
      default:
        Cmp = null;
        break;
    }
    if (!Cmp) {
      return null;
    }
    return <Cmp {...slice} key={slice.id} />;
  };

  render() {
    // Set the document object
    const { page } = this.state;
    const { title, body } = page.data;
    return (
      <React.Fragment>
        <Helmet
          title={`${title.text} | Citizen Relations`}
          meta={getPageMeta(
            this.props.pageContext.globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        {body.map(slice => {
          return this.renderSlice(slice);
        })}
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query($uid: String!) {
    page: prismicCrisisCommunications(uid: { eq: $uid }) {
      uid
      data {
        seo_description {
          text
        }
        seo_image {
          twitter {
            url
            dimensions {
              width
              height
            }
          }
          facebook {
            url
            dimensions {
              width
              height
            }
          }
        }
        title {
          text
        }
        body {
          ... on PrismicCrisisCommunicationsBodyHero {
            id
            slice_type
            primary {
              heading {
                text
                html
              }
              subheading {
                text
                html
              }
              paragraph {
                html
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicCrisisCommunicationsBodyFeatureContent {
            id
            slice_type
            primary {
              theme
              tagline {
                text
                html
              }
              paragraph {
                text
                html
              }
            }
          }
        }
      }
    }
  }
`;

export default CrisisPage;
